import shopifyGenericDefaults from '../shopify-generic/config.js';

let quickAdd;

function AfterInit() {
  setTimeout(() => {
    quickAdd = new window.theme.QuickAdd(window.document.querySelector('#cm_results'));
  }, 500);
}

const updateCallback = () => {
  quickAdd?.init();
};

shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchResult').updateCallback = updateCallback;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  AfterInit,
  defaultView: 'list',
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'SearchBox', 'SearchBoxDialog'].includes(w.name),
    ),
    {
      name: 'CustomSearchBox',
      type: 'SearchBox',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 780,
      fields:
        !!window.Convermax.config?.extraFieldsAsFilters && shopifyGenericDefaults.fitmentSearch.baseFields,
    },
    {
      name: 'LinersCrossSell',
      type: 'RelatedItems',
      location: '#cm-related-headliners',
      template: 'product/linersCrossSell',
      count: 3,
      requestExtra: { linersCrossSell: true },
      useManualItems: true,
    },
  ],
};

window.Convermax.changeImage = (variantId, image) => (e) => {
  const variantImages = window.document.querySelectorAll(
    `.grid-product__color-image.grid-product__color-image--${variantId}`,
  );

  variantImages.forEach((element) => {
    element.style.backgroundImage = `url(${image}&height=380&width=380)`;
    element.classList.add('is-active');
  });

  e.target.classList.add('is-active');
};

window.Convermax.setDefaltImage = (variantId) => (e) => {
  const variantImages = window.document.querySelectorAll(
    `.grid-product__color-image.grid-product__color-image--${variantId}`,
  );

  variantImages.forEach((element) => element.classList.remove('is-active'));

  e.target.classList.remove('is-active');
};
