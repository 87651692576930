
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function linersCrossSellRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function scopeColorArrColorImageUrlVariantId3(colorOption, colorOptionIndex) {
                    var colorArr = colorOption.split('|');
                    var color = colorArr[0];
                    var image = colorArr[1];
                    var url = colorArr[2];
                    var variantId = colorArr[2].split('?variant=')[1];
                    return [_createElement('span', {
                            'className': 'color-swatch color-swatch--small color-swatch--' + color + ' color-swatch--with-image',
                            'style': { backgroundImage: 'url(' + image + '?crop=center&height=400&width=400)' },
                            'data-url': url,
                            'key': '13121'
                        }, _createElement('span', { 'className': 'visually-hidden' }, color))];
                }
                function repeatColorOption4(colorOption, colorOptionIndex, colorArr, color, image, url, variantId) {
                    return scopeColorArrColorImageUrlVariantId3.apply(this, [
                        colorOption,
                        colorOptionIndex
                    ]);
                }
                return _createElement('div', {
                    'className': 'grid-item grid-product',
                    'data-product-handle': this.handle,
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'grid-item__content' }, _createElement('a', {
                    'href': this.url,
                    'className': 'grid-item__link'
                }, _createElement('div', { 'className': 'grid-product__image-wrap' }, _createElement('div', { 'className': 'grid__image-ratio grid__image-ratio--square' }, _createElement('img', {
                    'className': 'lazy lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy'
                }))), _createElement('div', { 'className': 'grid-item__meta' }, _createElement('div', { 'className': 'grid-item__meta-main container-text' }, _createElement('div', mergeProps({ 'className': 'grid-product__title' }, { dangerouslySetInnerHTML: { __html: this.title } })), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '7770'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'grid-product__colors',
                        'key': '1188'
                    },
                    _map(this.product_colors.slice(0, 4), repeatColorOption4.bind(this)),
                    this.product_colors.length > 4 ? [_createElement('small', {
                            'className': 'color-swatch__more',
                            'key': '19631'
                        }, '+', this.product_colors.length - 4)] : null
                ]) : null))), _createElement('div', { 'className': 'list-bottom-info' }, _createElement('div', { 'className': 'grid-item__meta-secondary' }, _createElement('div', { 'className': 'grid-product__price' }, _createElement('span', { 'className': 'grid-product__price--current' }, _createElement('span', {
                    'aria-hidden': 'true',
                    'className': this.price_varies ? 'grid-product__price--from' : ''
                }, this.price_varies ? _createElement('span', { 'key': '2548' }, 'from ') : null, this.formatPrice(this.price), '\n                '), _createElement('span', { 'className': 'visually-hidden' }, this.formatPrice(this.price))), _createElement('button', { 'className': 'choose-text quick-add-btn js-quick-add-form js-modal-open-quick-add' }, '\n                CHOOSE OPTIONS\n              '))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []